var isBreakPoint = function (bp) {
  var bps = [320, 480, 768, 1024, 1440],
      w = $(window).width(),
      min, max
  for (var i = 0, l = bps.length; i < l; i++) {
    if (bps[i] === bp) {
      min = bps[i-1] || 0
      max = bps[i]
      break
    }
  }
  return w > min && w <= max
}