window.addEventListener("load", function () {
    console.log('ztest');
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#000"
            },
            "button": {
                "background": "#f40008"
            }
        },
        "position": "bottom-right",
        "content": {
            "message": "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.",
            "dismiss": "OK, verstanden",
            "link": "Mehr erfahren",
            "href": "https://aac.at/datenschutz/"
        }
    })
});