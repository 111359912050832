Barba.Dispatcher.on('transitionCompleted', function() {

    var AIRPORTS, filter_airports, filter_options, get_airports;

    // Structure for Types, Brands, Models and Airports
    AIRPORTS = {
      'jet': {
        'cessna_textron': {
          '500_series': ['linz'],
          '501_series': ['linz'],
          '510_series': ['linz'],
          '525_series': ['graz', 'linz'],
          '525m2_series': ['graz', 'linz'],
          '525a_series': ['graz', 'linz'],
          '525b_series': ['linz'],
          '550_series': ['linz'],
          '560_series': ['linz'],
          '560xl_series': ['graz', 'linz'],
          '560xls_series': ['graz', 'linz'],
          '560xlsp_series': ['graz', 'linz']
        }
      },
      'turbo_prop': {
        'cessna_textron': {
          '206_soloy': ['linz'],
          '207_soloy': ['linz'],
          '208': ['voeslau', 'graz', 'linz']
        },
        'piper': {
          'pa46_jet_prop': ['linz'],
          'pa46_meridian': ['voeslau', 'graz']
        }
      },
      'piston': {
        'airostar': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'american_champion': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'aquila': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'aviat': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'cessna': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'cirrus': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'commander': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'czech_sport': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'diamond': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'extra': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'hawker_beachcraft': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'liberty': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'mooney': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'partenavia': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'piper': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'reims': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'robin': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'robin_ceapr': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'ruschmeyer_atc': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'tecnam': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'ultra_light': {
          'all': ['voeslau', 'graz', 'linz']
        },
        'zlin': {
          'all': ['voeslau', 'graz', 'linz']
        }
      }
    };
    
    // Returns a list of airports for a given object (at any depth) via recursion
    // The returned airports are flattened and uniquified
    get_airports = function(object) {
      var airports;
      if (Array.isArray(object)) {
        return object;
      }
      airports = Object.keys(object).map(function(key) {
        return get_airports(object[key]);
      });
      airports = $.map(airports, function(airport) {
        return airport;
      });
      return airports.filter(function(value, index, self) {
        return self.indexOf(value) === index;
      });
    };

    // Hides and shows airports depending on the selected values
    filter_airports = function() {
      var brand, filtered_airports, model, type;
      type = $('[data-select=type]').val();
      brand = $('[data-select=brand]').val();
      model = $('[data-select=model]').val();

      // Filter airports
      if (AIRPORTS[type] === void 0) {
        filtered_airports = get_airports(AIRPORTS);
      } else if (AIRPORTS[type][brand] === void 0) {
        filtered_airports = get_airports(AIRPORTS[type]);
      } else if (AIRPORTS[type][brand][model] === void 0) {
        filtered_airports = get_airports(AIRPORTS[type][brand]);
      } else {
        filtered_airports = AIRPORTS[type][brand][model];
      }

      // Display filtered airports
      $('[data-airport]').addClass('location-hidden');
      filtered_airports.forEach(function(airport) {
        return $('[data-airport=' + airport + ']').removeClass('location-hidden');
      });

      // Show all airports if no type is selected
      if (AIRPORTS[type] === void 0) {
        $('[data-airport]').removeClass('location-hidden');
      }
    };

    // Hides or shows the right options for every dropdown
    filter_options = function() {
      var brand, brands, models, type;
      type = $('[data-select=type]').val();
      brand = $('[data-select=brand]').val();
      
      // Update brands dropdown
      if (AIRPORTS[type] !== void 0) {
        brands = Object.keys(AIRPORTS[type]);
        $('[data-select=brand] option:not([value=\'\'])').attr('disabled', 'disabled');
        brands.forEach(function(brand) {
          return $('[data-select=brand] option[value=' + brand + ']').removeAttr('disabled');
        });
      }

      // Update models dropdown
      if (AIRPORTS[type] !== void 0 && AIRPORTS[type][brand] !== void 0) {
        models = Object.keys(AIRPORTS[type][brand]);
        $('[data-select=model] option:not([value=\'\'])').attr('disabled', 'disabled');
        models.forEach(function(model) {
          return $('[data-select=model] option[value=' + model + ']').removeAttr('disabled');
        });
      }
    };

    // Whenever any select is changed the airports are updated
    // Also the options inside the selects are updated accordingly
    $('[data-select]').change(function() {
      
      // Toggle airports depending on the selects
      filter_airports();
      
      // Toggle dropdown options depending on the previous selects
      filter_options();

      // Reset selects whenever a previous select was changed
      // Also set disabled states accordingly
      if ($(this).data('select') === 'type') {
        $('[data-select=brand], [data-select=model]').val('');
        $('[data-select=brand]').attr('disabled', $(this).val() === '');
        $('[data-select=model]').attr('disabled', 'disabled');
      } else if ($(this).data('select') === 'brand') {
        $('[data-select=model]').val('');
        $('[data-select=model]').attr('disabled', $(this).val() === '');
      }
    });

    // A type might be selected from the beginning
    if ($('[data-select=type]').val() !== '') {
      $('[data-select=type]').change();
    }

});