Barba.Dispatcher.on('transitionCompleted', function() {

  var collapseTimeline = new TimelineLite({paused:true});
    
  collapseTimeline
    // .to($(".navbar, .scroll-navbar"),0.1,{boxShadow: "0 1px 0 rgba(0,0,0,0)", ease:Power3.easeOut})
    .set($("body"), {className:"+=js--nav-expanded"})
    .to($(".navbar .navbar__brand path"),0.3,{fill: "#3B3B3B", ease:Power3.easeOut}, "-=0.1")
    .to($(".mobile-navbar"),0.5,{y:0, boxShadow: "0 1px 0 rgba(0,0,0,.1)", ease:Power3.easeOut}, "-=0.2")
    .staggerFrom($(".mobile-navbar .nav-item"),0.7,{x:150, opacity: 0, ease:Expo.easeOut}, 0.06, "-=0.1");

  
  $("[data-toggle='collapse']").click(function() {
    
    if($("body").hasClass("js--nav-expanded")) {
      
      collapseTimeline.reverse();
      collapseTimeline.timeScale(2.5);
      $("[data-toggle='collapse']").removeClass("show");
    
    } else if(!$("body").hasClass("js--nav-expanded")) {
      
      $("[data-toggle='collapse']").addClass("show");
      collapseTimeline.play();
      collapseTimeline.timeScale(1);

    }
  });



  // Add box-shadow on menu when scrolled over 10px from top
  $(window).on('scroll load',function() { 

    var menuScrolled;
    menuScrolled = $(this).scrollTop() >= 10;
    
    if (menuScrolled) {
      $('body').addClass("js--nav-scrolled");
    }
    else {
      $('body').removeClass("js--nav-scrolled");
    }

    // Hidden sticky nav on home
    if ($(".home").length > 0) {
          
      // Show menu when scrolled over anchor
      var showMenu;
      // showMenu = $(this).scrollTop() >= anchorPos;
      showMenu = $(this).scrollTop() >= 200;
      
      if (showMenu) {
        $('body').addClass("js--show-scroll-navbar");
        TweenLite.to($(".scroll-navbar"),1,{y:0, ease:Expo.easeOut})
      }
      else {
        $('body').removeClass("js--show-scroll-navbar");
        TweenLite.to($(".scroll-navbar"),1,{y:-96, ease:Expo.easeOut})
      }

      // Instantly hide nav when scrolled to top
      if ($(this).scrollTop() <= 5) {
        $('body').addClass("js--top");
      }
      else {
        $('body').removeClass("js--top");   
      }
    }

  });
  
});