// SLIDERS
Barba.Dispatcher.on('transitionCompleted', function() {

  var multislider = $("[data-showcase-text]").length > 0,
      simpleslider = $("[data-slider='simple']").length > 0;
  console.log(multislider,'multislider');
  console.log(simpleslider,'simpleslider');
 
  if (multislider) {

    $('[data-showcase-text]').slick({
      cssEase: 'linear',
      dots: false,
      arrows: false,
      infinite: true,
      speed: 400,
      fade: true,
      draggable: false,
      adaptiveHeight: true,
      autoplay: false,
      asNavFor: '[data-showcase-img]',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    });

    $('[data-showcase-img]').slick({
      dots: true,
      infinite: true,
      speed: 600,
      asNavFor: '[data-showcase-text]',
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000
    });

    // On before slide change
    $('[data-showcase-text]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var $nextSlideContents = $(slick.$slides[nextSlide]).find("[data-showcase-animate]"),
          $currentSlideContents = $(slick.$slides[currentSlide]).find("[data-showcase-animate]"),
          slideNextAnimation = new TimelineMax();

      slideNextAnimation
        .set($nextSlideContents, {opacity: 0, y: 100})
        .staggerTo($currentSlideContents, 0.4, {y: -50, ease:Power2.easeIn}, 0.03);
    });      

    // On After slide change
    $('[data-showcase-text]').on('afterChange', function(event, slick, currentSlide){
      var $slideContents = $(slick.$slides[currentSlide]).find("[data-showcase-animate]");
      
      TweenMax.staggerTo($slideContents, 1.2, {opacity:1, y:0, ease:Expo.easeOut}, 0.09);
    });

  }


  if (simpleslider) {

    $('[data-slider="simple"]').slick({
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
  }

});