Barba.Dispatcher.on('transitionCompleted', function() {

  // toggle switch for KNX option

  $('.toggleknx').on('change', function(){
    if($(this).is(":checked")) {
      $('.product__option--included').removeClass('d-none');
      $('.product__option--excluded').addClass('d-none');
    } else {
      $('.product__option--included').addClass('d-none');
      $('.product__option--excluded').removeClass('d-none');
    }
  })

  // PRODUCT SWITCH

  // Select product 02
  if ($(".tpl-main-product__tabs").length > 0) {
    $(function() {
      $('.js-select-product-02').on('click', function() {

        // Sets selected tab nav link to active
        $('.js-select-product-02').addClass('active');
        $('.js-select-product-01').removeClass('active');

        // Sets current tab container to active
        $('.tpl-main-product__tabs__item--02').addClass('active');
        $('.tpl-main-product__tabs__item--01').removeClass('active');

        // Slide transition to product tab
        TweenLite.to($(".tpl-main-product__tabs__item--01 .product__col--left"),0.7,{opacity:0, x:-300, ease:Expo.easeInOut})
        TweenLite.to($(".tpl-main-product__tabs__item--02 .product__col--left"),0.8,{opacity:1, x:0, ease:Expo.easeOut, delay: 0.4})

        // Fade transition to product image
        TweenLite.to($(".tpl-main-product__tabs__item--01 .product__col--right"),1,{opacity:0, ease:Expo.easeInOut, delay: 0.5})
        TweenLite.to($(".tpl-main-product__tabs__item--02 .product__col--right"),1,{opacity:1, ease:Expo.easeOut, delay: 0.7})
      });
    });

    // Select product 01

    $(function() {
      $('.js-select-product-01').on('click', function() {
        
        // Sets selected tab nav link to active
        $('.js-select-product-01').addClass('active');
        $('.js-select-product-02').removeClass('active');

        // Sets current tab container to active
        $('.tpl-main-product__tabs__item--01').addClass('active');


        $('.tpl-main-product__tabs__item--02').removeClass('active');
        
        // Slide transition to product tab
        TweenLite.to($(".tpl-main-product__tabs__item--02 .product__col--left"),0.7,{opacity:0, x:300, ease:Expo.easeInOut})
        TweenLite.to($(".tpl-main-product__tabs__item--01 .product__col--left"),0.8,{opacity:1, x:0, ease:Expo.easeOut, delay: 0.4})
        
        // Fade transition to product image
        TweenLite.to($(".tpl-main-product__tabs__item--02 .product__col--right"),1,{opacity:0, ease:Expo.easeInOut, delay: 0.5})
        TweenLite.to($(".tpl-main-product__tabs__item--01 .product__col--right"),1,{opacity:1, ease:Expo.easeOut, delay: 0.7})
      });
    });
  }

  var $list = $('.tpl-module-list__wrap'),
      $listHeightExpanded = $list.find(".tpl-module-list__wrap__inner").height(),
      $listHeightCollapsed = $list.find(".tpl-module-list__item").outerHeight() * 5;

  function setHeight(){
    if ($list.hasClass('expanded')) {
      TweenLite.to($list, 1, {height: $listHeightCollapsed, ease:Expo.easeInOut})
    } else {
      TweenLite.to($list, 1, {height: $listHeightExpanded, ease:Expo.easeInOut})
    }
  }

  // Expand/Collapse modules
  $('.js-expand-modules').on('click', function(){
    setHeight();
    $list.toggleClass("expanded");
    $(this).toggleClass("expanded");
  });

  $(window).on('resize', function(){
    $listHeightCollapsed = $list.find(".tpl-module-list__item").outerHeight() * 5;
    
    if (!$list.hasClass('expanded')) {
      TweenLite.to($list, 1, {height: $listHeightCollapsed, ease:Expo.easeInOut})
    }
  }).resize();
  
});