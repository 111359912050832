$(function() {
  var FadeTransition, SlideTransition, SlideBackTransition, lastClickedEl;

  FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      /**
       * This function is automatically called as soon the Transition starts
       * this.newContainerLoading is a Promise for the loading of the new container
       * (Barba.js also comes with an handy Promise polyfill!)
       */
      // As soon the loading is finished and the old page is faded out, let's fade the new page
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {
      /**
       * this.oldContainer is the HTMLElement of the old Container
       */

      fadeOutAnimation = new TimelineMax();

      fadeOutAnimation
        // .to($(".navbar, .scroll-navbar"),0.7,{y:-96, ease:Expo.easeInOut})
        // .staggerTo($(".mobile-navbar .nav-item"),0.5,{y:-150, opacity: 0, ease:Power3.easeIn}, 0.06, 0)
        .set($("body"), {className:"-=js--nav-expanded"})
      
      return $(this.oldContainer).promise();
    },

    fadeIn: function() {
      /**
       * this.newContainer is the HTMLElement of the new Container
       * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
       * Please note, newContainer is available just after newContainerLoading is resolved!
       */

      var _this = this;
      var $el = $(this.newContainer);

      $(_this.oldContainer).hide();
      $($el.oldContainer).show();

      $(window).scrollTop(0);

      // transitionAnimation = new TimelineMax();
        
      // transitionAnimation
      //   .set($el,{opacity:0, visibility:"visible"})
      //   .to($el, 0.5,{opacity:1, ease:Power2.easeOut})
        // .from($(".navbar, .scroll-navbar"),1,{y:-96, ease:Expo.easeInOut}, 0.5)
        // .staggerFrom($("[data-transition-slide-up]"),1.6,{opacity:0, y:100, ease:Power2.easeOut}, 0.3, 0.5)
        // .staggerFrom($("[data-transition-stagger]"),0.9,{opacity:0, y:100, ease:Expo.easeOut}, 0.09, 0.5)
        // .staggerFrom($("[data-transition-stagger-slow]"),1.5,{opacity:0, y:100, ease:Expo.easeOut}, 0.1, 0.5);

      _this.done();
    }
  });

  SlideTransition = Barba.BaseTransition.extend({
    start: function() {
      /**
       * This function is automatically called as soon the Transition starts
       * this.newContainerLoading is a Promise for the loading of the new container
       * (Barba.js also comes with an handy Promise polyfill!)
       */

      // As soon the loading is finished and the old page is faded out, let's fade the new page
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {
      /**
       * this.oldContainer is the HTMLElement of the old Container
       */
      TweenMax.to($("main"),0.5,{x:"-30vw", opacity: 0, ease:Expo.easeIn});
      return $("main").animate({ opacity: 0}, 400).promise();
    },

    fadeIn: function() {
      /**
       * this.newContainer is the HTMLElement of the new Container
       * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
       * Please note, newContainer is available just after newContainerLoading is resolved!
       */

      var _this = this;
      var $el = $(this.newContainer);

      $(this.oldContainer).hide();

      $(window).scrollTop(0);

      TweenMax.from($("main"),0.5,{x:"30vw", ease:Expo.easeOut});

      _this.done();
    }
  });

  SlideBackTransition = Barba.BaseTransition.extend({
    start: function() {
      /**
       * This function is automatically called as soon the Transition starts
       * this.newContainerLoading is a Promise for the loading of the new container
       * (Barba.js also comes with an handy Promise polyfill!)
       */

      // As soon the loading is finished and the old page is faded out, let's fade the new page
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {
      /**
       * this.oldContainer is the HTMLElement of the old Container
       */
      TweenMax.to($("main"),0.5,{x:"30vw", opacity: 0, ease:Expo.easeIn});
      return $("main").animate({ opacity: 0}, 500).promise();
    },

    fadeIn: function() {
      /**
       * this.newContainer is the HTMLElement of the new Container
       * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
       * Please note, newContainer is available just after newContainerLoading is resolved!
       */

      var _this = this;
      var $el = $(this.newContainer);

      $(this.oldContainer).hide();

      TweenMax.from($("main"),0.5,{x:"-30vw", ease:Expo.easeOut});

      _this.done();
    }
  });

  lastClickedEl = null;
  Barba.Dispatcher.on('linkClicked', function(el) {
    return lastClickedEl = el;
  });
  Barba.Pjax.getTransition = function() {
    if ($(lastClickedEl).hasClass('tpl-module-list__item')) {
      return SlideTransition;
    } else if($(lastClickedEl).hasClass('breadcrumbs__item')) {
      return SlideBackTransition;
    } else {
      return FadeTransition;
    }
  };
  console.log('Barba',Barba);
  //Edit crauch - Quick and dirty damit slider usw. wieder neu intialisiert werden auch wenn die Seite schonmal aufgerufen wurde
  Barba.Pjax.cacheEnabled = false;
  return Barba.Pjax.start();
});