Barba.Dispatcher.on('transitionCompleted', function() {

  var controller = new ScrollMagic.Controller();

  $('[data-fade-in-up]').each(function() {
    
    var $scrollElements = $(this).find("[data-fade-element]"),
        scrollAnimation = new TimelineMax();

    scrollAnimation.staggerFrom($scrollElements,1,{opacity:0, y:100, ease:Expo.easeOut}, 0.15);

    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.8,
      reverse: false
    })

    .setTween(scrollAnimation)
    // .addIndicators()
    .addTo(controller);
  });

  $('[data-slide-up]').each(function() {
    
    var scrollAnimation = new TimelineMax();
    var $scrollElements = $(this).find("[data-slide-element]");

    scrollAnimation.staggerFrom($scrollElements,2,{y:100, ease:Power2.easeOut}, 0.06);

    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.8,
      reverse: false
    })

    .setTween(scrollAnimation)
    .addTo(controller);
  });

  // $(".product-slider__slide").find('video').get(0).pause();
  // $(".product-slider__slide").find('video').get(0).currentTime = 0;

  $(".product-slider__slide").each(function() {

    var $video = $(this).find('video').get(0);

    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.8,
      reverse: false
    })

    .addTo(controller)
    .on("enter", function () {
      $video.play();
    })

  });


  // if ($("[data-slider='product']").length > 0) {

  //   $("[data-slider='product']").each(function() {
      
  //     var $currentSlideVideo = $(this).find('video').get(0)

  //     var scene = new ScrollMagic.Scene({
  //       triggerElement: this,
  //       triggerHook: 0.8,
  //       reverse: false
  //     })

  //     .addTo(controller)
  //     .on("enter", function () {
  //       // $("[data-slider]").slick('slickPause');
  //       $currentSlideVideo.play();
  //       // $currentSlideVideo.addEventListener('ended', myHandler, false);
  //     })

  //   });


  //   // $("[data-slider='product']").on('init', function(slick){

  //   //   var $currentSlideVideo = $(".product-slider__slide.slick-current").find('video').get(0)

  //   //   var scene = new ScrollMagic.Scene({
  //   //     triggerElement: this,
  //   //     triggerHook: 0.8,
  //   //     reverse: false
  //   //   })

  //   //   .addTo(controller)
  //   //   .on("enter", function () {
  //   //     // $("[data-slider]").slick('slickPause');
  //   //     $currentSlideVideo.play();
  //   //     // $currentSlideVideo.addEventListener('ended', myHandler, false);
  //   //   })
      
  //   //   // function myHandler(e) {
  //   //   //   $('[data-slider]').slick('slickPlay');
  //   //   // }

  //   // });
  // }

});